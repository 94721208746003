import { IconType } from "react-icons";
import { AiOutlineFile } from "react-icons/ai";
import { DateTime } from "luxon";
import { CustomIconType, IconCadastros, IconListagens, IconRelatorios } from "@src/Assets/Icones";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { UseFormReturn, Path, Control } from "react-hook-form/dist/types";
import { IInscricao, IInscricaoEstado } from "@src/@types/cnpj_types";
import { CSSProperties } from "react";
import { ModeloReferencia } from "@gqlHooks";
import { DeepPartial } from "@apollo/client/utilities";
import { captureException, captureMessage } from "@sentry/react";
import { Id, toast, ToastContent, ToastOptions } from "react-toastify";
import { ApolloError } from "@apollo/client";
import { DropdownDefaultClick } from "@components/SweetAlert";

type NotUndefined<T> = T extends undefined | null ? never : T;

export const padraoCodigosBalanca: RegExp[] = [
	/^2[0-9A-Z]{4}0\d{6}\d{1}$/, // 0 - 2CCCC0TTTTTTDV  (C = letras/números)
	/^2[0-9A-Z]{4}00\d{5}\d{1}$/, // 1 - 2CCCC00PPPPPDV
	/^2[0-9A-Z]{5}\d{6}\d{1}$/, // 2 - 2CCCCCTTTTTTDV
	/^2[0-9A-Z]{5}0\d{5}\d{1}$/, // 3 - 2CCCCC0PPPPPDV
	/^2[0-9A-Z]{6}\d{5}\d{1}$/, // 4 - 2CCCCCCPPPPPDV
	/^2[0-9A-Z]{6}\d{5}\d{1}$/, // 5 - 2CCCCCCTTTTTDV
];

export const formatDate = (value: Date, formatting: Intl.DateTimeFormatOptions = { month: "short", day: "numeric", year: "numeric" }): string => {
	return new Intl.DateTimeFormat("pt-BR", formatting).format(new Date(value));
};

export const compareDate = (date1: string, date2: string) => {
	const date1Date = DateTime.fromFormat(date1, "dd/MM/yyyy");
	const date2Date = DateTime.fromFormat(date2, "dd/MM/yyyy");

	return date1Date.diff(date2Date, "days").days;
};

export const getDefaultIcon = (value: IconType | CustomIconType | "CAD" | "LIST" | "REL" | "") => {
	if (typeof value === "string") {
		switch (value) {
			case "CAD":
				return IconCadastros;
			case "LIST":
				return IconListagens;
			case "REL":
				return IconRelatorios;
			default:
				return AiOutlineFile;
		}
	}

	return value;
};

// Converte data de YYYY-MM-DD para DD/MM/YYYY

export function ConvertData(data: string) {
	let initialDate = data;

	const [year, month, day] = initialDate.split("-");
	initialDate = [day, month, year].join("/");

	return initialDate;
}

export function removeEqualObject<T>(obj: T, obj2: any): Partial<T> {
	const newObj: any = cloneDeep(obj);

	for (const key in obj) {
		const value = obj?.[key];
		const value2 = obj2?.[key];

		if (value === null || value === undefined) {
			if (value2 === null || value2 === undefined) {
				delete newObj[key];
			}
		} else if (value instanceof File) {
			if (value2 instanceof File) {
				if (isEqual(value, value2)) {
					delete newObj[key];
				}
			}
		} else if (value instanceof Date) {
			if (value2 instanceof Date) {
				if (value.getTime() === value2.getTime()) {
					delete newObj[key];
				}
			}
		} else if (Array.isArray(value)) {
			if (Array.isArray(value2)) {
				const indexes: number[] = [];

				value.forEach((item: any, index: number) => {
					const idx = value2.findIndex((item2: any) => isEqual(item2, item));

					if (idx !== -1) {
						indexes.push(index);
					}
				});

				if (indexes.length === value.length) {
					delete newObj[key];
				} else {
					newObj[key] = value.filter((item: any, index: number) => !indexes.includes(index));
				}
			}
		} else if (typeof value === "object") {
			newObj[key] = removeEqualObject(value, value2);
		} else if (value === value2) {
			delete newObj[key];
		}
	}

	return newObj;
}

export function parseGraphQLError(error: any): {
	message: string;
	fields: Record<string, string>;
} {
	const fields: Record<string, string> = {};
	let message = "";

	if ("graphQLErrors" in error && error.graphQLErrors.length > 0) {
		error.graphQLErrors.forEach((error: any) => {
			if ("fieldErrors" in error) {
				error.fieldErrors.forEach((fieldError: any) => {
					fields[fieldError.field.replaceAll("/", ".")] = fieldError.message;
				});
			}

			if (!message && "message" in error) {
				message = error.message;
			}
		});

		return { message, fields };
	}

	if (error.response) {
		return { message: error.response.data, fields };
	}

	if (error.message) {
		return { message: error.message, fields };
	}

	return { message: error.toString(), fields };
}

export function setFormErrors<TFormState extends UseFormReturn<any>>(
	errors: Partial<Record<keyof TFormState["getValues"], string>>,
	formState: TFormState,
	{
		transformKey = {},
		prefix,
	}: {
		transformKey?: Partial<Record<LiteralUnion<Path<TFormState["control"] extends Control<infer T> ? T : never>>, (value: string) => string>>;
		prefix?: string;
	} = {}
) {
	const returnObj: Record<string, any> = {};

	if (Object.keys(errors).length === 0) {
		formState.clearErrors();
		return returnObj;
	}

	for (const key in errors) {
		const keyArray = key.split(".")[0];
		const keyTransformed = `${prefix ? `${prefix}.` : ""}${transformKey[keyArray]?.(key) ?? key}`;

		formState.setError(keyTransformed, { type: "manual", message: errors[key] }, { shouldFocus: true });

		keyTransformed.split(".").reduce((acc, cur, idx, arr) => {
			if (idx === arr.length - 1) {
				acc[cur] = errors[key];
			} else {
				acc[cur] = acc[cur] ?? {};
			}

			return acc[cur];
		}, returnObj);
	}

	return returnObj;
}

export const flattenObject = <T extends Record<string, any>>(obj: T, prefix = ""): Record<string, any> => {
	return Object.keys(obj).reduce((acc, cur) => {
		const pre = prefix.length ? prefix + "." : "";

		if (obj[cur] == null) Object.assign(acc, { [pre + cur]: obj[cur] });
		else if (typeof obj[cur] === "object" && !(obj[cur] instanceof Date)) Object.assign(acc, flattenObject(obj[cur], pre + cur));
		else acc[pre + cur] = obj[cur];

		return acc;
	}, {} as Record<string, any>);
};

export const setMultipleValues = <TFormState extends UseFormReturn<any>, TValues extends TFormState extends UseFormReturn<infer U> ? U : never>(
	methods: TFormState,
	values: DeepPartial<TValues>,
	options?: Parameters<TFormState["setValue"]>[2]
) => {
	const obj = flattenObject(values);
	const keys = Object.keys(obj);

	keys.forEach((key) => {
		methods.setValue(key, obj[key], options);
	});
};

export async function setCnpjData(
	data: any,
	methods: any,
	fieldNames: {
		IdPais: string;
		IdEstado: string;
		IdCidade: string;
		InscricaoEstadual: string;
		NomeFantasia: string;
		Nome: string;
		DataNascimento: string;
		Cep: string;
		Logradouro: string;
		Bairro: string;
		Email: string;
		NumEndereco: string;
		Telefone: string;
		Celular: string;
		RegimeTributario: string;
		Complemento: string;
	}
) {
	const inscricoes_estaduais = data.estabelecimento.inscricoes_estaduais;

	if (inscricoes_estaduais.length != 0) {
		if (inscricoes_estaduais.length === 1) {
			methods.setValue(fieldNames.InscricaoEstadual, data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual);
		} else {
			const inscricao_estadual_estado = inscricoes_estaduais.map((inscricao: IInscricaoEstado) => {
				return inscricao.estado;
			});

			const inscricao_estadual = inscricoes_estaduais.map((inscricao: IInscricao) => {
				return inscricao.inscricao_estadual;
			});

			const indexEstado = inscricao_estadual_estado.map((estado: { id: number }) => {
				if (data.estabelecimento.estado.id === estado.id) {
					return estado;
				} else {
					return;
				}
			});

			const filtered = indexEstado.filter((filteredIndex: number) => {
				return filteredIndex !== undefined;
			});

			methods.setValue(fieldNames.InscricaoEstadual, inscricao_estadual[filtered[0].id]);
		}
	} else {
		methods.setValue(fieldNames.InscricaoEstadual, "");
	}

	if (data.estabelecimento.nome_fantasia != null) {
		methods.setValue(fieldNames.NomeFantasia, data.estabelecimento.nome_fantasia);
	} else {
		methods.setValue(fieldNames.NomeFantasia, "");
	}
	if (data.razao_social != null) {
		methods.setValue(fieldNames.Nome, data.razao_social);
	}
	if (data.estabelecimento.data_inicio_atividade) {
		methods.setValue(fieldNames.DataNascimento, new Date(data.estabelecimento.data_inicio_atividade));
	}
	if (data.estabelecimento.cep) {
		methods.setValue(fieldNames.Cep, data.estabelecimento.cep);
	}
	if (data.estabelecimento.logradouro) {
		methods.setValue(fieldNames.Logradouro, data.estabelecimento.logradouro);
	}
	if (data.estabelecimento.bairro) {
		methods.setValue(fieldNames.Bairro, data.estabelecimento.bairro);
	}
	if (data.estabelecimento.email) {
		methods.setValue(fieldNames.Email, data.estabelecimento.email);
	}
	if (data.estabelecimento.numero) {
		methods.setValue(fieldNames.NumEndereco, data.estabelecimento.numero);
	}
	if (data.estabelecimento.telefone1) {
		const telefone = data.estabelecimento.ddd1 + data.estabelecimento.telefone1;
		const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
		const result = telefone.replace(regex, "($1) $2-$3");
		methods.setValue(fieldNames.Telefone, result);
	}
	if (data.estabelecimento.telefone2) {
		const telefone2 = data.estabelecimento.ddd1 + data.estabelecimento.telefone2;
		const regex2 = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;
		const result2 = telefone2.replace(regex2, "($1) $2-$3");
		methods.setValue(fieldNames.Celular, result2);
	}

	if (data.simples) {
		if (data.simples.simples === "Sim") {
			methods.setValue(fieldNames.RegimeTributario, "SIMPLES_NACIONAL");
		} else {
			methods.setValue(fieldNames.RegimeTributario, "REGIME_NORMAL");
		}
	} else {
		methods.setValue(fieldNames.RegimeTributario, "REGIME_NORMAL");
	}
	if (data.estabelecimento.complemento) {
		methods.setValue(fieldNames.Complemento, data.estabelecimento.complemento);
	}
}

type StyleType = CSSProperties | Record<`--${string}`, string | number>;
type StyleCheck = [StyleType, boolean];
type StyleArgs = StyleCheck | StyleType;

export const styles = (...styles: StyleArgs[]) => {
	const cssStyle: CSSProperties = {};

	styles.forEach((styleCheck) => {
		if (Array.isArray(styleCheck)) {
			const [style, check] = styleCheck;

			if (check) {
				Object.assign(cssStyle, style);
			}
		} else {
			Object.assign(cssStyle, styleCheck);
		}
	});

	return cssStyle;
};
export function setCondicoes(condicoes: any) {
	const todasCondicoes = [];

	if (condicoes.RegimeTributarioICMSCondicao) {
		if (condicoes.RegimeTributarioICMSCondicao === "CONTRIBUINTE_ICMS") {
			todasCondicoes.push("Regime Tributário ICMS - Contribuinte");
		} else if (condicoes.RegimeTributarioICMSCondicao === "CONTRIBUINTE_ISENTO") {
			todasCondicoes.push("Regime Tributário ICMS - Contribuinte Isento");
		} else if (condicoes.RegimeTributarioICMSCondicao === "NAO_CONTRIBUINTE") {
			todasCondicoes.push("Regime Tributário ICMS - Não Contribuinte");
		}
	}

	if (condicoes.RegimeTributarioCondicao) {
		if (condicoes.RegimeTributarioCondicao === "SIMPLES_NACIONAL") {
			todasCondicoes.push("\nRegime Tributário - Simples Nacional");
		} else if (condicoes.RegimeTributarioCondicao === "SIMPLES_NACIONAL_EXCESSO_DE_SUBLIMITE") {
			todasCondicoes.push("\nRegime Tributário - Simples Nacional Excesso de Sublimite");
		} else if (condicoes.RegimeTributarioCondicao === "REGIME_NORMAL") {
			todasCondicoes.push("\nRegime Tributário - Regime Normal");
		} else if (condicoes.RegimeTributarioCondicao === "SIMPLES_NACIONAL_MEI") {
			todasCondicoes.push("\nRegime Tributário - Simples Nacional MEI");
		}
	}

	if (condicoes.TipoConsumidorCondicao) {
		if (condicoes.TipoConsumidorCondicao === "CONSUMIDOR_FINAL") {
			todasCondicoes.push("\nFinalidade da Nota - Consumidor Final");
		} else if (condicoes.TipoConsumidorCondicao === "REVENDEDOR") {
			todasCondicoes.push("\nFinalidade da Nota - Revendedor");
		}
	}

	todasCondicoes.push(condicoes.PessoaCondicaoNome);

	return todasCondicoes;
}

export const fakeClickCenter = (btn: HTMLElement) => {
	const isTouch = "ontouchstart" in window;

	const x = btn.getBoundingClientRect().x + btn.getBoundingClientRect().width / 2 + window.scrollX;
	const y = btn.getBoundingClientRect().y + btn.getBoundingClientRect().height / 2 + window.scrollY;

	btn.dispatchEvent(new MouseEvent(isTouch ? "touchstart" : "mousedown", { bubbles: true, clientX: x, clientY: y }));
	setTimeout(() => {
		btn.dispatchEvent(new MouseEvent(isTouch ? "touchend" : "mouseup"));
	}, 100);
};

export const roundTo = (num: number, decimalPlaces = 2) => {
	return Math.round((num + Number.EPSILON) * 10 ** decimalPlaces) / 10 ** decimalPlaces;
};

export const getInnerSize = (e: HTMLElement | null) => {
	if (e) {
		const el = getComputedStyle(e);

		let { clientHeight, clientWidth } = e;

		clientHeight -= parseFloat(el.paddingTop) + parseFloat(el.paddingBottom);
		clientWidth -= parseFloat(el.paddingLeft) + parseFloat(el.paddingRight);

		return { clientHeight, clientWidth };
	}

	return { clientHeight: 0, clientWidth: 0 };
};

export const reduceUndefinedArray = <T,>(acc: NotUndefined<T>[], cur: T): NotUndefined<T>[] => {
	if (cur) acc.push(cur as NotUndefined<T>);

	return acc;
};

export const reduceArrayToObject = <T,>(acc: any, cur: T): NotUndefined<T> => ({ ...acc, ...cur });

export const reduceUndefinedObject = <T,>(acc: T) => {
	const obj = cloneDeep(acc);

	for (const key in obj) {
		if (obj[key] !== null) {
			if (obj[key] === undefined) {
				delete obj[key];
			} else if (Array.isArray(obj[key])) {
				(obj as any)[key] = (obj[key] as unknown as any[]).reduce(reduceUndefinedArray, []) as unknown as T[keyof T];

				if ((obj[key] as unknown as any[])?.length === 0) {
					delete obj[key];
				}
			} else if (typeof obj[key] === "object" && !(obj[key] instanceof Date) && !(obj[key] instanceof File)) {
				obj[key] = reduceUndefinedObject(obj[key]);
				if (Object.keys((obj as any)[key]).length === 0) {
					delete obj[key];
				}
			}
		}
	}

	return obj;
};

export const emptyToNull = (val?: string | null) => {
	if (!val) return null;

	return val;
};

const BLACKLIST_CPF: Array<string> = [
	"00000000000",
	"11111111111",
	"22222222222",
	"33333333333",
	"44444444444",
	"55555555555",
	"66666666666",
	"77777777777",
	"88888888888",
	"99999999999",
	"12345678909",
];

const BLACKLIST_CNPJ: Array<string> = [
	"00000000000000",
	"11111111111111",
	"22222222222222",
	"33333333333333",
	"44444444444444",
	"55555555555555",
	"66666666666666",
	"77777777777777",
	"88888888888888",
	"99999999999999",
];

const validarCPF = (cpf: string): boolean => {
	const calculaDigitoVerificador = (digitos: string): number => {
		const numeros: number[] = digitos.split("").map((numero) => parseInt(numero, 10));
		const modulus: number = numeros.length + 1;
		const multiplicado: number[] = numeros.map((numero, index) => numero * (modulus - index));
		const mod: number = multiplicado.reduce((buffer, numero) => buffer + numero) % 11;
		return mod < 2 ? 0 : 11 - mod;
	};
	cpf = cpf.replace(/\D/g, "");
	if (!cpf || cpf.length !== 11 || BLACKLIST_CPF.includes(cpf)) return false;
	let numeros: string = cpf.substring(0, 9);
	numeros += calculaDigitoVerificador(numeros);
	numeros += calculaDigitoVerificador(numeros);
	return numeros.substring(-2) === cpf.substring(-2);
};

export const validarCNPJ = (cnpj: string): boolean => {
	const calculaDigitoVerificador = (digitos: string): number => {
		let index = 2;
		const reverse: number[] = digitos
			.split("")
			.reverse()
			.map((digito) => parseInt(digito));
		const soma: number = reverse.reduce((buffer, numero) => {
			buffer += numero * index;
			index = index === 9 ? 2 : index + 1;
			return buffer;
		}, 0);
		const mod: number = soma % 11;
		return mod < 2 ? 0 : 11 - mod;
	};
	cnpj = cnpj.replace(/\D/g, "");
	if (!cnpj || cnpj.length !== 14 || BLACKLIST_CNPJ.includes(cnpj)) return false;
	let numeros: string = cnpj.substring(0, 12);
	numeros += calculaDigitoVerificador(numeros);
	numeros += calculaDigitoVerificador(numeros);
	return numeros.substring(-2) === cnpj.substring(-2);
};

export const validarCPF_CNPJ = (cpf_cnpj: string): boolean => {
	cpf_cnpj = cpf_cnpj.replace(/\D/g, "");
	if (![11, 14].includes(cpf_cnpj.length)) return false;
	if (cpf_cnpj.length === 11) {
		return validarCPF(cpf_cnpj);
	} else if (cpf_cnpj.length === 14) {
		return validarCNPJ(cpf_cnpj);
	}
	return false;
};

export const validaChaveAcesso = (chaveAcesso: string) => {
	//gera o digito verificador da chave e compara se está igual ao da chave
	if (!chaveAcesso || chaveAcesso.trim().length !== 44) {
		return false;
	}

	const chaveParcial = chaveAcesso.substring(0, 43).split("").map(Number);
	const multiplicadores = [2, 3, 4, 5, 6, 7, 8, 9];

	let i = 42;
	let somaPonderada = 0;

	while (i >= 0) {
		for (let j = 0; j < multiplicadores.length && i >= 0; j++) {
			somaPonderada += chaveParcial[i] * multiplicadores[j];
			i -= 1;
		}
	}

	const resto = somaPonderada % 11;
	const dv = resto === 0 || resto === 1 ? 0 : 11 - resto;

	return String(dv) === chaveAcesso.substring(43, 44);
};

const STRING_TO_MODELO_REFERENCIA = {
	"55": ModeloReferencia.Nfe,
	"65": ModeloReferencia.Nfce,
};

export const chaveAcessoParaValor = (chaveAcesso: string) => {
	/**
	 * Formação da chave de acesso por número de digitos
	 * 02 - UF
	 * 04 - Ano Mes da emissão
	 * 14 - CNPJ do emitente
	 * 02 - Modelo da NFe
	 * 03 - Série da NFe
	 * 09 - Número da NFe
	 * 01 - Forma de emissão da NF-e
	 * 08 - Código numérico
	 * 01 - Dígito verificador
	 */
	const codigoUF = parseInt(chaveAcesso.substring(0, 2));
	const anoMesEmissao = chaveAcesso.substring(2, 6);
	const cnpjEmitente = chaveAcesso.substring(6, 20).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"); // Formata o cnpj
	const modeloDocumento: ModeloReferencia = STRING_TO_MODELO_REFERENCIA[chaveAcesso.substring(20, 22) as keyof typeof STRING_TO_MODELO_REFERENCIA];
	const serieDocumento = parseInt(chaveAcesso.substring(22, 25));
	const numeroDocumento = parseInt(chaveAcesso.substring(25, 34));
	const formaEmissao = chaveAcesso.substring(34, 35);
	const codigoNumerico = chaveAcesso.substring(35, 43);
	const digitoVerificador = chaveAcesso.substring(43, 44);

	return {
		codigoUF,
		anoMesEmissao,
		cnpjEmitente,
		modeloDocumento,
		serieDocumento,
		numeroDocumento,
		formaEmissao,
		codigoNumerico,
		digitoVerificador,
	};
};

export const saveErrorMessage = (error: any) => {
	if (error instanceof Error) {
		captureException(error);
	} else {
		captureMessage(error);
	}
};

export const downloadFile = (filePath: string, fileName: string, successMessage: string, toastId?: Id) => {
	fetch(filePath, {
		method: "GET",
		credentials: "include",
		headers: {
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "GET",
			"Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
		},
	})
		.then((response) => response.blob())
		.then((blob) => {
			const url = window.URL.createObjectURL(new Blob([blob]));

			const link = document.createElement("a");
			link.href = url;
			link.download = fileName;

			document.body.appendChild(link);

			link.click();
			link.parentNode?.removeChild(link);

			if (toastId) {
				toast.update(toastId, {
					render: successMessage,
					type: "success",
					autoClose: 3000,
					theme: "colored",
					isLoading: false,

					closeOnClick: true,
					closeButton: true,
				});
			} else {
				toast.success(successMessage, {
					autoClose: 3000,
					theme: "colored",

					closeOnClick: true,
					closeButton: true,
				});
			}
		});
};

export const isNotaPratica = () => import.meta.env.VITE_SYSTEM_NAME === "nota-pratica";

export const defaultUrlParams = () => (window.location.search ? window.location.search : "?u=1");

export const getCookie = (name: string) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);

	if (parts.length === 2) return parts.pop()?.split(";").shift();

	return undefined;
};

export const getErrorValues = (error: ApolloError) => {
	return error.graphQLErrors[0] as typeof error.graphQLErrors[0] & { [key: string]: any };
};

export const getBarCodeFormat = (value: string): "EAN13" | "CODE128" => {
	const num = value.padStart(18, "0");
	const dv = parseInt(num[17]);
	const numWithoutDV = num.slice(0, 17);

	let sum = 0;

	for (let i = 0, factor = 3; i < 17; i++, factor = 4 - factor) {
		sum += factor * parseInt(numWithoutDV[i]);
	}

	const mmc = Math.ceil(sum / 10) * 10;
	const mod = mmc - sum;

	if (dv !== mod || (value.length !== 12 && value.length !== 13)) return "CODE128";
	return "EAN13";
};

export const formatCurrency = (amount: number) => amount.toLocaleString(undefined, { style: "currency", currency: "BRL" });

export const openModalUpgradePlan = (isMobile: boolean) => {
	const getUrl = () => {
		if (isMobile) return import.meta.env.VITE_LINK_UPGRADE_PLANO_MOBILE;
		return import.meta.env.VITE_LINK_UPGRADE_PLANO_DESKTOP;
	};

	return DropdownDefaultClick({
		title: "Upgrade de Plano",
		body: "Essas informações estão disponíveis apenas para empresas do plano Pro e Business. Consulte nossa equipe comercial para realizar um upgrade de plano.",
		confirmButtonText: "Continuar",
		cancelButtonText: "Cancelar",
		focusCancel: false,
		didOpen: () => {
			(document.getElementsByClassName("swal2-confirm")[0] as HTMLButtonElement).blur();
		},
		onConfirm: () => {
			window.open(`${getUrl()}&text=Ol%C3%A1,%20tudo%20bem?%20Preciso%20de%20um%20upgrade%20de%20plano...`, "_blank", "noopener noreferrer");
		},
	});
};

export function reaisEmExtenso(valor: number | string): string {
	const ex = [
		["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
		["dez", "vinte", "trinta", "quarenta", "cinquenta", "sessenta", "setenta", "oitenta", "noventa"],
		["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
		[
			"mil",
			"milhão",
			"bilhão",
			"trilhão",
			"quadrilhão",
			"quintilhão",
			"sextilhão",
			"setilhão",
			"octilhão",
			"nonilhão",
			"decilhão",
			"undecilhão",
			"dodecilhão",
			"tredecilhão",
			"quatrodecilhão",
			"quindecilhão",
			"sedecilhão",
			"septendecilhão",
			"octencilhão",
			"nonencilhão",
		],
	] as const;

	const e = " e ";
	const singularReal = "real";
	const pluralReal = "reais";
	const singularCentavo = "centavo";
	const pluralCentavo = "centavos";

	const n = typeof valor === "number" ? valor.toFixed(2).split(".") : valor.replace(/\D/g, "").split(",");
	const r: string[] = [];

	for (let j = 0, f = n.length - 1; j <= f; j++) {
		const v = j && n[j] ? n[j] : n[j];
		if (!v) continue;

		const a = v.length % 3;
		const vArray = v.slice(a).match(/\d{3}/g);
		const vParts = a ? [v.slice(0, a)] : [];
		const vFinal = vArray ? vParts.concat(vArray) : vParts;

		const s: string[] = [];
		for (let i = 0, l = vFinal.length; i < l; i++) {
			const num = parseInt(vFinal[i]);
			if (!num) continue;

			const t = num % 100;
			const centenas = (num / 100) >> 0;
			const dezenas = (t / 10) >> 0;
			const unidades = t % 10;

			let palavra = "";
			if (t < 20) {
				palavra = ex[0][t];
			} else {
				palavra = ex[1][dezenas - 1];
				if (unidades) {
					palavra += e + ex[0][unidades];
				}
			}

			s.push(
				centenas > 0
					? centenas === 1 && !t
						? (s.length > 0 ? e.substring(1, 3) : "") + ex[2][0]
						: (s.length > 0 && dezenas === 0 && unidades === 0 ? e.substring(1, 3) : "") + ex[2][centenas] + (t ? e + palavra : "")
					: ((unidades > 0 || dezenas > 0) && s.length > 0 ? e.substring(1, 3) : "") + palavra,
				l - i - 2 > -1 ? (num > 1 && l - i - 2 > 0 ? ex[3][l - i - 2].replace("ão", "ões") : ex[3][l - i - 2]) : ""
			);
		}

		let result = s.length > 1 ? `${s.slice(0, -1).join(" ")}${s[1] === "mil" && s.length === 2 ? " " : e}${s.slice(-1)}` : s.join("");
		result = result.substring(0, result[result.length - 2] === "e" ? result.length - 3 : result.length);
		result = s[0] === "um" && s[1] === "mil" ? result.substring(3, result.length) : result;
		if (result) {
			const currencyText = j === 0 ? `${parseInt(vFinal.join("")) > 1 ? pluralReal : singularReal}` : `${parseInt(vFinal.join("")) > 1 ? pluralCentavo : singularCentavo}`;
			r.push(result + " " + currencyText);
		}
	}

	return r
		.join(e)
		.replace(new RegExp(` ${e.trim()} reais`, "g"), ` reais`)
		.replace(new RegExp(` ${e.trim()} centavos`, "g"), ` centavos`);
}

export const toastify = (content: ToastContent, options: ToastOptions) => {
	let autoClose = 3000;

	if (options.type === "warning" || options.type === "error") {
		autoClose = 5000;
	}

	toast(content, {
		autoClose,
		theme: "colored",
		closeButton: true,
		closeOnClick: true,
		pauseOnFocusLoss: true,
		pauseOnHover: true,
		...options,
	});
};

export const buscaCodigoProdutoBalanca = (CODIGO_BARRAS_BALANCA: string, codigo: string): { index: number; codigoProduto: string | undefined } => {
	let index = Number(CODIGO_BARRAS_BALANCA ?? -1);
	if (index !== -1) {
		if (!padraoCodigosBalanca[index].test(codigo.replace(/^0+/, ""))) {
			index = -1;
		}
	}

	let codigoProduto;
	if (index !== undefined && index !== -1) {
		// Captura o C: código do produto
		switch (index) {
			case 0:
			case 1: {
				codigoProduto = (codigo.substring(1, 5) as string).replace(/^0+/, "");
				break;
			}

			case 2:
			case 3: {
				codigoProduto = (codigo.substring(1, 6) as string).replace(/^0+/, "");
				break;
			}

			case 4:
			case 5: {
				codigoProduto = (codigo.substring(1, 7) as string).replace(/^0+/, "");
				break;
			}

			default:
				break;
		}
	}

	return {
		index,
		codigoProduto,
	};
};

export const calculaValoresBalanca = (
	precoVenda: "1" | "2",
	casasDecimais: { valor: number; quantidade: number },
	codigo: string,
	produto: { valorVenda: number; valorVendaAlt: number },
	CODIGO_BARRAS_BALANCA: string,
	indexAlterado?: number
): { valor: number; quantidade: number; peso: number } | undefined => {
	const indexPadraoCodigoBalanca = (codigo: string) => {
		let index = Number(CODIGO_BARRAS_BALANCA ?? -1);
		if (index !== -1) {
			if (!padraoCodigosBalanca[index].test(codigo)) {
				index = -1;
			}
		}

		return index;
	};

	const index = indexAlterado ?? indexPadraoCodigoBalanca(codigo.replace(/^0+/, ""));
	const codigoDeBalanca = index !== undefined && index !== -1;

	let valorTotal = 0;
	let quantidadeTotal = 1;
	let pesoTotal = 0;
	if (codigoDeBalanca) {
		switch (index) {
			// Captura o T: Valor Total
			case 0:
			case 2: {
				valorTotal = Number(codigo.substring(6, 12) as string) / 100;
				quantidadeTotal = roundTo(valorTotal / (precoVenda === "1" ? produto.valorVenda : produto.valorVendaAlt), casasDecimais.quantidade);
				break;
			}

			case 5: {
				valorTotal = Number(codigo.substring(7, 12) as string) / 100;
				quantidadeTotal = roundTo(valorTotal / (precoVenda === "1" ? produto.valorVenda : produto.valorVendaAlt), casasDecimais.quantidade);
				break;
			}

			// Captura o P: Peso
			case 1:
			case 3:
			case 4: {
				pesoTotal = Number(codigo.substring(7, 12) as string) / 100;
				valorTotal = roundTo(pesoTotal * (precoVenda === "1" ? produto.valorVenda : produto.valorVendaAlt), casasDecimais.valor);
				quantidadeTotal = pesoTotal;
				break;
			}

			default:
				break;
		}

		return {
			valor: valorTotal,
			quantidade: quantidadeTotal,
			peso: pesoTotal,
		};
	}

	return undefined;
};
