import LoadingIcon from "@src/Assets/Icones/LoadingIcon";
import "../Style/Suporte.Style.scss";
import { FC, useEffect, useMemo, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import { GrClose } from "react-icons/gr";
import { MdOutlineSupportAgent } from "react-icons/md";
import { Icons, toast } from "react-toastify";
import { useAcessoChamadoTomTicketLazyQuery, useAcessoChatTomTicketLazyQuery, useBuscaNovasMensagensTomTicketLazyQuery } from "@gqlHooks";
import { isNotaPratica, parseGraphQLError } from "@utils";
import { IoChatboxOutline, IoDocumentTextOutline } from "react-icons/io5";
import Button from "@components/Button";
import { useMediaQuery } from "@hooks/useMediaQuery";
import classNames from "classnames";
import { PiYoutubeLogo } from "react-icons/pi";

type SuporteTomTicketProps = {
	isButton: boolean;
	changeOnSm: boolean;
};

let intervalBuscaMensagens: NodeJS.Timeout | null = null;

const SuporteTomTicket: FC<SuporteTomTicketProps> = ({ isButton, changeOnSm }) => {
	const src =
		"https://doma.tomticket.com/scripts-chat/chat.min.js" +
		"?id=EP65056" +
		"&account=4163584P01032024044119" +
		"&autoOpen=1" +
		"&hideWhenOffline=0" +
		"&d=doma" +
		"&ts=" +
		new Date().getTime() +
		"&ref=" +
		encodeURIComponent(document.URL);

	const [showModal, setShowModal] = useState<boolean>(false);
	const [chatVisibility, setChatVisibility] = useState<boolean>(false);
	const [chatAtivo, setChatAtivo] = useState<boolean>(false);
	const [chatJwt, setChatJwt] = useState<string | undefined>(undefined);
	const [numMensagensNaoLidas, setNumMensagensNaoLidas] = useState<number>(0);
	const isLg = useMediaQuery("(min-width: 576px)");

	const [buscaUrlChamado] = useAcessoChamadoTomTicketLazyQuery({
		fetchPolicy: "network-only",
	});

	const [buscaJwtChat] = useAcessoChatTomTicketLazyQuery({
		fetchPolicy: "network-only",
	});

	const [buscaNovasMensagensQuery] = useBuscaNovasMensagensTomTicketLazyQuery({
		fetchPolicy: "network-only",
		onCompleted: (data) => {
			if (data?.buscaNovasMensagensTomTicket.Erro) {
				console.log("Falha ao atualizar novas mensagens TomTicket");
				return;
			}

			if (data?.buscaNovasMensagensTomTicket.NumeroMensagens !== numMensagensNaoLidas) {
				const notificacao = document.getElementById("notificacao") as HTMLElement;
				if (notificacao) {
					notificacao.classList.add("d-none");
					notificacao.classList.remove("d-none");
				}
				setNumMensagensNaoLidas(data?.buscaNovasMensagensTomTicket.NumeroMensagens ?? 0);
			}
		},
	});

	// Fica ouvindo a mudança do conteúdo do chat e assim atualiza o eventListener do frame
	const observer = useMemo(() => {
		return new MutationObserver((mutations) => {
			let div;
			for (const mutation of mutations) {
				// Conteúdo principal
				if (mutation.type === "childList" && mutation.target instanceof Element && mutation.target.classList[0] == "sub-content") {
					if (div != mutation.target) {
						div = mutation.target;
						setTimeout(() => {
							refreshListener();
						}, 600);
					}
				}
				storageEventHandler();
			}
		});
	}, []);

	const handleClickChamado = async () => {
		const toastId = toast.loading("Registrando solicitação, aguarde...", {
			type: toast.TYPE.DEFAULT,
			icon: <LoadingIcon />,
			autoClose: 1000,
			pauseOnFocusLoss: true,
			pauseOnHover: true,
			theme: "colored",
			isLoading: true,
		});
		setShowModal(false);
		const closeMenu = document.getElementsByClassName("toggle-icon__container")[0] as HTMLElement;
		!isLg && closeMenu?.click();

		await buscaUrlChamado().then((res) => {
			let err;
			if (res.error) {
				const { fields } = parseGraphQLError(res.error);
				err = fields?.[Object.keys(fields)[0]];
			}

			if (err) {
				toast.update(toastId, {
					render: err,
					type: toast.TYPE.ERROR,
					icon: Icons.error,
					autoClose: 2000,
					theme: "colored",
					isLoading: false,
					progress: undefined,
					closeButton: true,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
				});
			} else {
				toast.update(toastId, {
					render: "Seus chamados foram abertos em uma nova guia",
					type: toast.TYPE.INFO,
					icon: Icons.info,
					autoClose: 2000,
					theme: "colored",
					isLoading: false,
					progress: undefined,
					closeButton: true,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
				});
				setTimeout(() => {
					window.open(res.data?.acessoChamadoTomTicket, "_blank");
				}, 100);
			}
		});
	};

	const handleClickChat = async (forceClose = false) => {
		showModal && setShowModal(false);
		if (!chatJwt && !forceClose && !chatAtivo) {
			const toastId = toast.loading("Registrando solicitação, aguarde...", {
				type: toast.TYPE.DEFAULT,
				icon: <LoadingIcon />,
				autoClose: 1000,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				theme: "colored",
				isLoading: true,
			});

			await buscaJwtChat().then((res) => {
				const jwt = res.data?.acessoChatTomTicket;
				if (!jwt) {
					toast.update(toastId, {
						render: "Falha ao abrir o chat",
						type: toast.TYPE.ERROR,
						icon: Icons.error,
						autoClose: 2000,
						theme: "colored",
						isLoading: false,
						progress: undefined,
						closeButton: true,
						closeOnClick: true,
						pauseOnFocusLoss: true,
						pauseOnHover: true,
					});
					return;
				}
				setTimeout(() => {
					toast.update(toastId, {
						render: "Chat aberto com sucesso",
						type: toast.TYPE.SUCCESS,
						icon: Icons.success,
						autoClose: 2000,
						theme: "colored",
						isLoading: false,
						progress: undefined,
						closeButton: true,
						closeOnClick: true,
						pauseOnFocusLoss: true,
						pauseOnHover: true,
					});
				}, 450);
				setChatJwt(jwt);
				localStorage.setItem("jwtLocalTomTicket", jwt);
				injectScript(jwt, forceClose);
			});
		} else {
			const localToken = !chatJwt && chatAtivo ? localStorage.getItem("jwtLocalTomTicket") : chatJwt;
			localStorage.setItem("dataHoraUltimaMsgTomTicket", new Date().toString());
			setNumMensagensNaoLidas(0);
			injectScript(localToken ?? "", forceClose);
		}
		const closeMenu = document.getElementsByClassName("toggle-icon__container")[0] as HTMLElement;
		!isLg && closeMenu?.click();
	};

	const injectScript = (tokenJwt: string | undefined, forceClose = false) => {
		const changeChatState = () => {
			let widget = document.getElementById("comTomTicketChatWidget");
			let container = document.getElementById("comTomTicketChatContainer");

			if (forceClose) {
				for (let i = 0; i < 100; i++) {
					widget = document.getElementById("comTomTicketChatWidget");
					container = document.getElementById("comTomTicketChatContainer");

					if (widget && container) break;
				}
			}

			if (container) {
				container.classList.remove("tt-fadeOutDown");
				container.classList.remove("tt-fadeInUp");
				container.classList.add("tt-fadeInUp");
				container.style.display = forceClose || chatVisibility ? "none" : "block";
			}
			if (widget) {
				widget.classList.add("tt-fadeInUp");
				widget.classList.add("tt-animated");
				widget.classList.add("tt-fadeOutDown");
				widget.setAttribute("style", "display:none !important");
			}

			if (forceClose || chatVisibility) {
				observer.disconnect();
			} else {
				// Aguarda o frame do chat carregar para iniciar o observer
				setTimeout(() => {
					const frame = document.getElementById("comTomTicketChatFrame") as HTMLIFrameElement;
					if (frame && frame.contentDocument) observer.observe(frame.contentDocument, { attributes: true, childList: true, subtree: true });
					refreshListener();
				}, 1000);
			}

			setChatVisibility(forceClose ? false : !chatVisibility);
		};

		const script = document.getElementById("tomticket-chat-script");
		if (!script) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			document.tomticketChatLoaderScriptVersion = 2;

			const ttChatLoaderS = document.createElement("script");
			ttChatLoaderS.id = "tomticket-chat-script";
			ttChatLoaderS.src = src + tokenJwt;

			ttChatLoaderS.onload = () => changeChatState();

			document.body.appendChild(ttChatLoaderS);
		} else {
			changeChatState();
		}
	};

	// Função para atualizar o eventListener quando o chat muda seu conteúdo
	const refreshListener = () => {
		const frame = document.getElementById("comTomTicketChatFrame") as HTMLIFrameElement;
		if (frame && frame.contentDocument) {
			const handleFrameClick = (e: any) => {
				if (e.target.parentNode.id == "Combined-Shape" || e.target.parentNode.className == "close") {
					handleClickChat(true);
				}
			};

			if (frame.contentWindow) {
				const window = frame.contentWindow as any;
				const OriginalAudio = window.Audio;

				window.Audio = function (src: any) {
					const chat = document.getElementById("comTomTicketChatContainer");
					if (chat) {
						setTimeout(() => {
							const display = getComputedStyle(chat).display;
							if (display === "none") buscaMensagens();
						}, 200);
					}

					const audioInstance = new OriginalAudio(src);
					return audioInstance;
				};
			}

			frame.contentDocument.removeEventListener("click", handleFrameClick, false);
			frame.contentDocument.addEventListener("click", handleFrameClick, false);
		}
	};

	useEffect(() => {
		window.addEventListener("storage", storageEventHandler, false);
		storageEventHandler();

		return () => {
			if (intervalBuscaMensagens) {
				clearInterval(intervalBuscaMensagens);
				intervalBuscaMensagens = null;
			}
		};
	}, []);

	const storageEventHandler = () => {
		const sessaoChat = localStorage.getItem("com_tomticket_x_csrf_token");
		setChatAtivo(!!sessaoChat);

		if (!intervalBuscaMensagens) {
			intervalBuscaMensagens = setInterval(() => {
				chatAtivo && !chatVisibility && buscaMensagens();
			}, 60000);
		}
	};

	const buscaMensagens = () => {
		const dataUltimaMsg = new Date(localStorage.getItem("dataHoraUltimaMsgTomTicket") ?? "2000-01-01T00:00:00.000Z");
		buscaNovasMensagensQuery({
			variables: {
				filtro: {
					DataUltimaMensagem: dataUltimaMsg,
				},
			},
		}).catch((error) => {
			console.log(error);
		});
	};

	return (
		<>
			{!isButton ? (
				<li
					className="main-menu__scrollbar_item"
					title={"Ajuda do suporte"}
					onClick={() => (isNotaPratica() ? handleClickChamado() : chatVisibility || chatAtivo ? handleClickChat() : setShowModal(true))}
				>
					<a className="cursor-pointer">
						<MdOutlineSupportAgent className="page-link" />
						<span>Ajuda do Suporte</span>
					</a>
				</li>
			) : (
				<>
					{!isLg && changeOnSm ? (
						<li className="main-nav__options__item">
							<a
								className={classNames("main-nav__options__item__link cursor-pointer", { "chat-active": chatAtivo })}
								onClick={() => (isNotaPratica() ? handleClickChamado() : chatVisibility || chatAtivo ? handleClickChat() : setShowModal(true))}
							>
								<MdOutlineSupportAgent size={14} />
								{numMensagensNaoLidas > 0 && (
									<Badge id="notificacao" className={classNames("notificacao", { "d-none": numMensagensNaoLidas <= 0 })} pill>
										{numMensagensNaoLidas}
									</Badge>
								)}
							</a>
						</li>
					) : (
						<Button
							variant="light"
							className={classNames("btn-ajuda-suporte", { "chat-active": chatAtivo, "notificacao-nao-vista": numMensagensNaoLidas > 0 })}
							onClick={() => (isNotaPratica() ? handleClickChamado() : chatVisibility || chatAtivo ? handleClickChat() : setShowModal(true))}
						>
							<MdOutlineSupportAgent size={20} className="me-75" />
							<span className={classNames("align-middle", { "me-50": numMensagensNaoLidas > 0 })}>Ajuda do suporte</span>
							{numMensagensNaoLidas > 0 && (
								<Badge id="notificacao" className={classNames("notificacao", { "d-none": numMensagensNaoLidas <= 0 })} pill>
									{numMensagensNaoLidas}
								</Badge>
							)}
						</Button>
					)}
				</>
			)}
			<Modal
				show={showModal}
				centered
				className={classNames("modal-suporte-tom-ticket", {
					doma: !isNotaPratica(),
				})}
				backdropClassName="modal-suporte-tom-ticket__backdrop"
				backdrop="static"
				onEscapeKeyDown={() => {
					setShowModal(false);
				}}
			>
				<Modal.Header className="pb-0 d-flex flex-column align-items-start">
					<span className="h4 d-block mt-1 mb-0">Ajuda do suporte</span>
					<small>Selecione uma das opções:</small>

					<button
						className="modal-close-btn"
						onClick={(e) => {
							e.preventDefault();
							setShowModal(false);
						}}
						type="button"
					>
						<GrClose />
					</button>
				</Modal.Header>
				<Modal.Body>
					<div className="card-suporte__container">
						<Button variant="light" className="card-suporte__btn" onClick={() => handleClickChat()}>
							<IoChatboxOutline size={isLg ? 20 : 14} className="me-50" />
							<span className="align-middle">Chat ao vivo</span>
						</Button>
						<Button variant="light" className="card-suporte__btn" onClick={handleClickChamado}>
							<IoDocumentTextOutline size={isLg ? 20 : 22} className="me-50" />
							<span className="align-middle">Meus chamados</span>
						</Button>
						<Button
							variant="light"
							className="card-suporte__btn"
							onClick={() => window.open("https://www.youtube.com/playlist?list=PLCMnN0l24gmuOvtaKhsybN5WX0wh2eWHo", "_blank", "noopener noreferrer")}
						>
							<PiYoutubeLogo size={isLg ? 22 : 24} className="me-50" />
							<span className="align-middle">Tutoriais</span>
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default SuporteTomTicket;
